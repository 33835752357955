<template>
  <div>
    <h3>31 August 2010 (0.190.24)</h3>
    <p>
      <u>Front End</u>
      <br>- When deleting a character, you will no longer snap to the first character.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- You can no longer get stuck behind the trees at the Sentinel Camp.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Fixed bug in the concert instrument Quick-Builds, where you would get into a bad state if you smashed yourself while Quick-Building.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Only one player loots the treasure chest after killing the dragon in the Dragon Den. LEGO Club You can now reliably enter the LEGO Club door.
    </p>
    <p>
      <u>Property</u>
      <br>- You can cancel the Property Description dialog by taking off your Thinking Hat, hitting the blue checkmark or switching Minifigure.
      <br>- You cannot close the description dialog in any other way, while the text is being validated or if there are red words displayed in the window.
    </p>
    <p>
      <u>Build</u>
      <br>- All interrupted Brick Mode sessions (due to client or server disconnects) are now captured and re-created upon re-entry.
    </p>
    <p>
      <u>Friends</u>
      <br>- Mail will now show the sender’s approved name, even if the name has been approved since they sent the mail.
    </p>
    <p>
      <u>Chat</u>
      <br>- The behavior chat filter is now even more robust.
    </p>
    <p>
      <u>Racing</u>
      <br>- Imagination crate is shaded correctly.
    </p>
    <p>
      <u>General</u>
      <br>- All Rocketlauncher icons are consistent and updated.
      <br>- You will receive clearer communication on what’s happening when a name is approved or later changed by a Mythran.


    </p>
    <h3>30 August 2010 (0.190.23)</h3>
    <p>
      <u>Venture Explorer</u>
      <br>- Story plaquesnow refer to Sky Lane as the captain of the spaceship.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- You will see a faster falling anchor in the Ape fight.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Brick Fury’s health bar is now visible.
      <br>- You will now be able to smash the crypt statue.
    </p>
    <p>
      <u>Racing</u>
      <br>- Rear car panel should no longer be missing bricks.
      <br>- “Wrong Way” message works correctly.
      <br>- The lap counter now acts consistently at the start of the second race.
      <br>- The Racing Leaderboard now appears only if you finish a race.
    </p>
    <p>
      <u>Mail</u>
      <br>- It will now be possible to send stacks of items through mail.
    </p>
    <p>
      <u>Pets</u>
      <br>- When renaming a pet, closing the naming rules dialog will properly return you to the rename dialog.
    </p>
    <p>
      <u>Build</u>
      <br>- Connectivity has been improved in Brick Mode.
    </p>
    <p>
      <u>Audio</u>
      <br>- You now have music on you Avant Garden property!
    </p>
    <p>
      <u>General</u>
      <br>- Chat bubbles will stay on screen longer.


    </p>
    <h3>27 August 2010 (0.190.22)</h3>
    <p>
      <u>Build</u>
      <br>- The “Shift” key allows you to pick up and drop modules more reliably.
    </p>
    <p>
      <u>Shooting Gallerys</u>
      <br>- Shooting Gallery score summary is now displayed.
    </p>
    <p>
      <u>Audio</u>
      <br>- Cave ambiences added to Gnarled Forest.
    </p>
    <p>
      <u>General</u>
      <br>- Shields and decals added for equipable Minifigure items.
      <br>- Consumables will no longer vanish from paperdoll or the action bar when running out of them.


    </p>
    <h3>26 August 2010 (0.190.21)</h3>
    <p>
      <u>Front End</u>
      <br>- When you log into the game while it is closed, you will now see the appropriate message.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Mech turrets no longer flash a white plane.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The Brick Puzzle in the Paradox Refinery now works.
    </p>
    <p>
      <u>Build</u>
      <br>- Placement of large models on player Property is now more reliable.
      <br>- You now auto-kick non-Best Friends when donning your Thinking Hat to edit property.
    </p>
    <p>
      <u>Pets</u>
      <br>- Pets tell you what is required if you are unable to have them use an ability.
    </p>
    <p>
      <u>Audio</u>
      <br>- User Interface – now with sound!
      <br>- New sounds for loot pick-up.
    </p>
    <p>
      <u>General</u>
      <br>- Mouse cursor is no longer flickering when playing on a Mac.
      <br>- Bob Tips should now appear above missions, mail, and other windows, preventing any unintended window closing.


    </p>
    <h3>26 August 2010 (0.190.20) </h3>
    <p>
      <u>Front End</u>
      <br>- Losing connection to the server between character select and the loading screen will no longer leave you with a white screen forever.
    </p>
    <p>
      <u>Build</u>
      <br>- Moving the mouse quickly while clicking on a block no longer causes the block to jump far away from mouse.


    </p>
    <h3>25 August 2010 (0.190.19)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- Epsilon’s muzzle flash now shows up correctly.
    </p>
    <p>
      <u>Property</u>
      <br>- You should no longer fall through elevator platform while trying to complete the Maelstrom Pushback.
    </p>
    <p>
      <u>Build</u>
      <br>- You are once again able to load models in Brick Build.
    </p>
    <p>
      <u>Racing</u>
      <br>- Only one car spawns for every player racing.
    </p>
    <p>
      <u>Chat</u>
      <br>- While speaking to a Game Master, you’ll now see the actual text instead of “## ##”.
    </p>
    <p>
      <u>General</u>
      <br>- Updated effects for the dragon pets.
      <br>- If a world server shuts down, the server will save all characters in that world.


    </p>
    <h3>25 August 2010 (0.190.18)</h3>
    <p>
      <u>Venture Explorer</u>
      <br>- Bob’s display screen now has its proper effects.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Monument elevators now flash before smashing themselves.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The gameplay guiding light beam has been restored to the Pirate hiding in the barrel.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Quick-Build consoles at the Paradox Facility now appear in the same location during the building and built phases.
    </p>
    <p>
      <u>Racing</u>
      <br>- The mission to collect 9 Imagination orbs during a race can now be completed on both race tracks.
    </p>
    <p>
      <u>Build</u>
      <br>- In Brick Mode, bricks that cannot be placed glow bright red.
    </p>
    <p>
      <u>Property</u>
      <br>- Property plaque does not appear until after the Maelstrom Pushback has been completed.
    </p>
    <p>
      <u>Mail</u>
      <br>- Players can now use mail in German.
    </p>
    <p>
      <u>General</u>
      <br>- All in-game moderator characters are henceforth known as “Mythrans”.
      <br>- Beast Master Achievement should now count pets properly.


    </p>
    <h3>24 August 2010 (0.190.17)</h3>
    <p>
      <u>Nimbus Station</u>
      <br>- Concert instrument builds work more consistently.
    </p>
    <p>
      <u>Starbase 3001</u>
      <br>- Minifigures in Starbase 3001 are now called Hess LaCoil and Otto Matic.
    </p>
    <p>
      <u>Property</u>
      <br>- When a player arrives at a property with smashed models on it, bricks on the ground is all that they will see.
    </p>
    <p>
      <u>Equipment</u>
      <br>- Left-handed items no longer flip 180 degrees in your hand after you double jump.
    </p>
    <h3>23 August 2010 (0.190.16)</h3>
    <p>
      <u>Nimbus Station</u>
      <br>- Non player characters now appear correctly in the mission pop-ups.
    </p>
    <p>
      <u>Build</u>
      <br>- The text field on chat blocks can now extend beyond the edge of the behavior editor canvas.
    </p>
    <p>
      <u>Mail</u>
      <br>- Mail knows when your inventory is full, and will no longer appear to delete attachments.
    </p>
    <p>
      <u>Backpack</u>
      <br>- Icons have been updated.
    </p>
    <p>
      <u>Chat</u>
      <br>- Chat artwork has been updated.
    </p>
    <p>
      <u>Pets</u>
      <br>- In the crab, crocodile, and skunk minigames, the darker blue color has been replaced with yellow, to avoid color confusion.


    </p>
    <h3>23 August 2010 (0.190.15)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- You should no longer be able to get stuck behind the trees against the Sentinel wall.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Physics near the entrance to the concert area has been fixed so you no longer get stuck.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- There is no longer invisible terrain next to the bridge near Pirate Arch.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Platform has been fixed, so Brick Fury no longer floats.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- It is no longer possible to get stuck under the Quick-Build bridge.
    </p>
    <p>
      <u>Property</u>
      <br>- Property Navigation will default to Sort By Reputation.
      <br>- You earn reputation when someone stays on your property for more than 5 minutes.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- There is now a 100 strip limit across all states.
      <br>- Chat clock’s input does not change unless you change it yourself.
    </p>
    <p>
      <u>Audio</u>
      <br>- New and improved Achievement sound.
      <br>- New sword sound.
    </p>
    <p>
      <u>General</u>
      <br>- You can no longer use emotes while in the Shooting Gallery.


    </p>
    <h3>20 August 2010 (0.190.14)</h3>
    <p>
      <u>Gnarled Forest</u>
      <br>- Race track obstacles have been balanced.
    </p>
    <p>
      <u>Build</u>
      <br>- When in Behavior Mode, right and left clicking both work at a distance.
      <br>- Clicking outside the Behavior Editor will no longer cause the editor to stop functioning.
      <br>- To delete behavior blocks, you now drop them onto the palette instead of anywhere outside the canvas.
    </p>
    <p>
      <u>General</u>
      <br>- You will no longer lose coins when smashed on a battle instance.
      <br>- You will be able to emote using the Speedchat menu again.


    </p>
    <h3>19 August 2010 (0.190.13)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Brick Fury now has a muzzle flash when he shoots his gun.
    </p>
    <p>
      <u>Foot Race</u>
      <br>- The Foot Race timer is now visible if you open the News Screen as the race is starting.
    </p>
    <p>
      <u>Mail</u>
      <br>- Composing mail should now always show the correct cost.
    </p>
    <p>
      <u>User Interface</u>
      <br>- There’s a new icon image for the Maelstrom Hammer.


    </p>
    <h3>19 August 2010 (0.190.12)</h3>
    <p>
      <u>Behaviors</u>
      <br>- Drop down boxes that have lost focus will no longer stay in the behavior editor.
      <br>- You are no longer able to befriend a Game Master.


    </p>
    <h3>19 August 2010 (0.190.11)</h3>
    <p>
      <u>Front End</u>
      <br>- When logging in to an account without any created Minifigures, backing out of Character Create will no longer cause the new/delete buttons to disappear.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The red dragon platform should now have the correct collision.
      <br>- The dragon tooth icon is now black like the in-game asset.
    </p>
    <p>
      <u>Vendors</u>
      <br>- Selling and buying back items in quantities will now result in the correct amount of currency being exchanged.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Leaving property and returning should no longer cause you to lose behaviors.
      <br>- Empty state tabs should now disappear from editor, and should no longer cause additional block malfunctions.
    </p>
    <p>
      <u>Pets</u>
      <br>- All pieces in the warthog pet taming puzzle are now un-clickable.
    </p>
    <p>
      <u>Racing</u>
      <br>- You will not be able to emote during racing.
      <br>- If you crash while racing, you will respawn at 40 % of top speed and with half of your Imagination (rounding up).
    </p>
    <p>
      <u>Leaderboards</u>
      <br>- Racing leaderboards should no longer stay onscreen after exiting racing.
    </p>
    <p>
      <u>Mail</u>
      <br>- You can remove items from mail the same way as from trade and other user interfaces.
    </p>
    <p>
      <u>General</u>
      <br>- LEGO Dice skill now works.
      <br>- You won’t be attacked before the loading screen goes away.


    </p>
    <h3>18 August 2010 (0.190.10)</h3>
    <p>
      <u>Front End</u>
      <br>- Custom name field is now limited to 24 characters.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Grass no longer clips with plant structure.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Random items should no longer appear under the roots leading to the tree.
    </p>
    <p>
      <u>Starbase 3001</u>
      <br>- Model Vendor’s anvil icon now depicts Minifigure with different clothes.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Behavior cloud tooltips will no longer block editor buttons.
      <br>- Now showing username behavior names in the Backpack.
      <br>- Now showing the submit button for renaming behaviors when you open the behavior editor.
    </p>
    <p>
      <u>Chat</u>
      <br>- Blacklisted words are now being highlighted when whispering to Best Friends.
    </p>
    <p>
      <u>General</u>
      <br>- Item rollover now shows the correct sell amounts.
      <br>- Buying back items function now works.
      <br>- Quick-Builds are now interrupted when enemies attack you (unless using assembly skill).


    </p>
    <h3>17 August 2010 (0.190.9)</h3>
    <p>
      <u>Backpack</u>
      <br>- Faction Kits now have a unique icon in your Backpack when receiving them from the Faction Representative.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- “Rattle the Bones” Achievement now shows the skeleton cage.
      <br>- Pet taming mini-game puzzle pieces are now showing correctly for all pets in Gnarled Forest.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- “Bang a Gong” Achievement now shows the Dragon Gong, replacing the Bell Shrine.
      <br>- The white ninja gi is now… white.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Boomerang asset has been changed to a Frisbee.
    </p>
    <p>
      <u>Build</u>
      <br>- The correct text now appears when hovering over your tools in Brick Mode and Model Mode.
    </p>
    <p>
      <u>Passport</u>
      <br>- The flag icons in the worlds now match the flags in the Passport.
    </p>
    <p>
      <u>General</u>
      <br>- Faction Tokens now look like tokens, and not stamps.
      <br>- Missions with a choice of rewards will now display a text to reduce confusion around having a choice versus getting everything displayed.


    </p>
    <h3>16 August 2010 (0.190.8)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Smashables have been added in the Cavalry Hill scene.


    </p>
    <h3>13 August 2010 (0.190.7)</h3>
    <p>
      <u>Nimbus Station</u>
      <br>- Trees have been added.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Fire effects have been added to the flying dragon’s attacks in the Dragon Battle.
    </p>
    <p>
      <u>User Interface</u>
      <br>- Faction Tokens no longer have placeholder icons.
      <br>- Mission names have been updated.
    </p>
    <p>
      <u>Build</u>
      <br>- Models are now placed on their lowest point, so for instance rocket modules are no longer imbedded in the ground.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Large property models with a “follow” behavior will no longer follow indefinitely.


    </p>
    <h3>13 August 2010 (0.190.6)</h3>
    <p>
      <u>Reward Models</u>
      <br>- Raft model should be correct now.
    </p>
    <p>
      <u>Passport</u>
      <br>- Achievements are now positioned correctly in the Passport.


    </p>
    <h3>12 August 2010 (0.190.5)</h3>
    <p>
      <u>Front End</u>
      <br>- Front end links to various pages such as Sign in, Forgot password, and Registration now directs you to the right local page.
    </p>
    <p>
      <u>User Interface</u>
      <br>- Minimap icons now update properly.
      <br>- Mission names in the Backpack will no longer overlap.
      <br>- Window size will now change with resolution changes, etc.
    </p>
    <p>
      <u>Pets</u>
      <br>- The correct total number of pets in a zone summary table now shows up.
    </p>
    <p>
      <u>General</u>
      <br>- Coins now show the new U from the logo instead of the old hand logo.


    </p>
    <h3>12 August 2010 (0.190.4)</h3>
    <p>
      <u>Front End</u>
      <br>- Text on the “wrong password” dialog box when deleting a character no longer overlaps the arrow button.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Monument elevators do not smash until they reach the top anymore.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- You should no longer be able to stand on the plants near the launch pad from Nimbus Station.
    </p>
    <p>
      <u>Behavior</u>
      <br>- The drop down menus in the behavior editor have been fixed.
    </p>
    <p>
      <u>Property</u>
      <br>- Gnarled Forest property build area is now correctly sized.
    </p>
    <p>
      <u>Chat</u>
      <br>- You are yet again able to type the letter ‘d’ in chat.


    </p>
    <h3>12 August 2010 (0.190.3)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Dragon will no longer look strange when hit with special-effect color glow weapons.
      <br>- “Great Ninja Gate” is now “Ravencloud Gate”.
    </p>
    <p>
      <u>Build</u>
      <br>- The saving as well as the loading user interface has been fixed. Usable models such as rockets and cars are not placeable on a player’s property and grayed out in Model Mode, or a message is displayed with information on this.
    </p>
    <p>
      <u>Property</u>
      <br>- You will no longer incorrectly be told that your property is approved by a moderator, if the access type is set to “public” immediately after completing the property tutorial missions.
    </p>
    <p>
      <u>User Interface</u>
      <br>- Interaction icons will appear higher up if the object has a name.


    </p>
    <h3>11 August 2010 (0.190.2)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- Paths up the monument can no longer be skipped for a lower finish time.
    </p>
    <p>
      <u>Options</u>
      <br>- Resolution switching has a confirmation dialog.
      <br>- Options menu buttons has the correct selection state.
      <br>- Minimizing chat or minimap, resizing, then reopening chat or minimap will no longer cause those widgets to be in the wrong location.
    </p>
    <p>
      <u>Skills and Items</u>
      <br>- The anvil will now disappear just as it drops the last loot.
    </p>
    <p>
      <u>Build</u>
      <br>- Minimap no longer appears in build mode with venture gear equipped.
      <br>- Releasing outside of a button on the action bar in Model and Brick Mode will no longer cause the button to appear selected.
      <br>- Build Mode action bar buttons now have a twofold tooltip which first shows the name of the tool and after a moment it expands to a more descriptive tooltip.
    </p>
    <p>
      <u>Chat</u>
      <br>- Banned words are now being banned when you whisper to a Best Friend.


    </p>
    <h3>10 August 2010 (0.190.1)</h3>
    <p>
      <u>Gnarled Forest</u>
      <br>- Loot from sharks will not get stuck in air when using money magnet under sharks.
    </p>
    <p>
      <u>Race</u>
      <br>- The ‘Wrong Way’ notice should now disappear as soon as you crash.
    </p>
    <p>
      <u>Build</u>
      <br>- Group controls have been docked to the left side of the screen instead of between the undo/redo buttons in Brick Build.


    </p>
    <h3>9 August 2010 (0.190.0)</h3>
    <p>
      <u>Front End</u>
      <br>- We now have a LEGO logo outro!
      <br>- Icon for game patcher has been changed.
      <br>- You should no longer get stuck at the “Submitting Username” screen.
      <br>- The patcher and client no longer explicitly require administrator access to the computer.
      <br>- Continuous spinning during character creation has been fixed.
      <br>- New spawn animation plays when you log in to the game.
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- There are no more missing textures on the Spaceship.
      <br>- You will now be properly stunned while viewing the cinematic showing Sky Lane.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The air bouncer on the orange path now has a wind effect.
      <br>- There’s no longer an invisible collision at the entrance to the Spiderling Cave.
      <br>- Camera cinematic showing the Monument now plays after building the bridge Quick-Build.
      <br>- Players who salute most Sentinel characters in-game will receive salutes back.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Fixed physics on the shoreline rocks so you no longer clip through some of the areas.
      <br>- Camera returns after cinematics have been improved.
      <br>- Rad Eccles’ first 3 missions to trade crystals for backpack space are now more explicit; you get one slot for each crystal.
      <br>- It is no longer possible to get stuck on the mission giver when pressing ‘Escape’ after cancelling out of reward selection for Faction or Kit missions.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- More mushrooms have been added to the respawn area of the jail.
      <br>- If you hit the Maelstrom Chest from too short a distance, only you, not those around you, will be smashed.
      <br>- Changed the mouth of the tortoise.
      <br>- The organ is now a minigame! Click on the piano keys and play some sweet music.
      <br>- Shooting Gallery targets have been improved.
      <br>- If a ninja is freed in Gnarled Forest, he shows up in Forbidden Valley.
      <br>- Lion taming puzzle fixed! If you timeout during the puzzle, you will be able to try again.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- You now have to wear the ninja cowl to earn the Ninja Cowl Achievement.
      <br>- Locations for all pets have been adjusted, so all pieces for the puzzles are selectable.
      <br>- Ronin now have a taunt animation.
      <br>- It is now more obvious when the dragon hits you.
      <br>- The ‘Repair 3 Pipes’ mission now should be registering properly.
      <br>- Trees in the mantis temple area now have their foliage back.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Now has a soundtrack!
    </p>
    <p>
      <u>Starbase 3001</u>
      <br>- Showcase models now rotate and hover.
    </p>
    <p>
      <u>LEGO Club</u>
      <br>- Added info that the teleport takes you back to Nimbus Station.
    </p>
    <p>
      <u>Property</u>
      <br>- You can no longer gain health, imagination or armor recharge on property that has been claimed.
      <br>- You will now receive a pop-up message and an in-game email when your property has been approved or rejected.
      <br>- Property boundaries should now be visible.
      <br>- Changing property access no longer requires changing name/description too.
    </p>
    <p>
      <u>Build Modes</u>
      <br>- When entering brick mode, selection tool will now always be selected by default.
      <br>- Chat will minimize when entering Build Mode.
      <br>- You no longer lose bricks when server crashes while you’re in Brick Mode.
      <br>- Brick Mode will no longer crash when attempting to place a brick in an invalid location.
      <br>- Transparent bricks now work properly in brick-by-brick mode.
      <br>- The rotate button has been fixed.
      <br>- All castle models now align correctly.
      <br>- Several reward models which could not previously be placed are now placeable.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- “Follow” now faces the movement-direction and will no longer miss the target.
      <br>- Best Friends can now trade and open custom behaviors.
      <br>- Behavior names can have foreign characters.
    </p>
    <p>
      <u>Racing</u>
      <br>- Once the loot tables are set up, racing rewards will scale based on number of racers, as originally designed.
      <br>- New and improved racing intro cinematic.
    </p>
    <p>
      <u>Pets</u>
      <br>- The pet taming mini-games now have music.
    </p>
    <p>
      <u>Skills and Items</u>
      <br>- Added lightning to the Lightning Sky Sword.
      <br>- Daredevil Fire Extinguisher has been fixed.
      <br>- Updated magnet hold effect.
      <br>- Updated glow shaders Buccaneer and Space Marauder.
      <br>- Using the fortress turret, you are now once again immune to enemies.
    </p>
    <p>
      <u>Chat</u>
      <br>- Chat commands can have foreign characters.
      <br>- Word suggest now appears correctly, no matter the size of the chat window.
    </p>
    <p>
      <u>Backpack</u>
      <br>- The layout has been updated.
    </p>
    <p>
      <u>Trade</u>
      <br>- Rockets and cars no longer have a trade issue.
    </p>
    <p>
      <u>Friends</u>
      <br>- You should be able to ask an existing friend to be your friend.
      <br>- Friendlier friends list…
    </p>
    <p>
      <u>User Interface</u>
      <br>- Missions will be sorted properly in the passport.
      <br>- Mission complete tooltips now properly display the icon.
      <br>- ‘Escape’ will now properly close tooltips.
    </p>
    <p>
      <u>General</u>
      <br>- You will now respawn with 4 Health, 6 Imagination and no armor.
      <br>- Invisible wall should no longer be an issue.
      <br>- There is now a reward for earning 1,000 Reputation.
      <br>- Options menu no longer causes incorrect window dimensions at certain resolutions.


    </p>
    <h3>7 August 2010 (0.189.13) </h3>
    <p>
      <u>User Interface</u>
      <br>- The minigame user interface will no longer act up when you leave the game. Private
    </p>
    <p>
      <u>Property</u>
      <br>- The property manager now shows the number of days the property has existed instead of the date it was created.


    </p>
    <h3>6 August 2010 (0.189.11) </h3>
    <p>
      <u>Friends</u>
      <br>- The social menu should now read ‘blocked’ in all instances of ignored.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The mission art has been updated.
    </p>
    <p>
      <u>Foot Race</u>
      <br>- You will no longer get stuck at the end of the foot race, if you finish it with less than one second left.
    </p>
    <p>
      <u>Maps</u>
      <br>- World Maps now include useful tips.


    </p>
    <h3>6 August 2010 (0.189.10) </h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- A Dragon Battle Map has been added to the Universe Map.
    </p>
    <p>
      <u>Front End</u>
      <br>- The list of names in the Namenator has been updated.
    </p>
    <p>
      <u>Chat</u>
      <br>- The list of words on the whitelist has been updated.


    </p>
    <h3>5 August 2010 (0.189.9) </h3>
    <p>
      <u>Front End</u>
      <br>- The list of available Namenator names has been updated.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- All objects now have physics and collisions, even on low spec computers.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- All objects now have physics and collisions, even on low spec computers.
      <br>- You can now complete the “A Licensed Technician” achievement.
    </p>
    <p>
      <u>Racing</u>
      <br>- The racing lobby timer should now appear after a player exits and re-enters the queue.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Behavior names now have a limited character length.
      <br>- Creature dropdown is now re-enabled.
    </p>
    <p>
      <u>Chat</u>
      <br>- Clicking on ‘Word Suggest’ should no longer cause the chat box to become stuck open.
    </p>
    <p>
      <u>User Interface</u>
      <br>- The ‘f’ key now toggles the friends list.
    </p>
    <p>
      <u>Build</u>
      <br>- You will no longer lose bricks when a server crash occurs while in Brick Mode.
      <br>- The correct message should now appear when first using “Bring Into” a model.


    </p>
    <h3>5 August 2010 (0.189.8) </h3>
    <p>
      <u>Build</u>
      <br>- You can no longer duplicate parts in a modular build by dragging the icon of an equipped model to the ghosts and hitting shift.
    </p>
    <p>
      <u>Mini-games</u>
      <br>- If you are in the queue for racing or survival and hit ESC you will now exit the queue as you should.
      <br>- If you close the race queue popup after hitting “Start” but before countdown ends, you will now exit the queue as expected.
    </p>
    <p>
      <u>Chat</u>
      <br>- Best Friends are now unable to send blacklisted words in private chat. GM’s can’t use blacklisted words either.


    </p>
    <h3>4 August 2010 (0.189.7) </h3>
    <p>
      <u>Build</u>
      <br>- Reward models are now usable immediately after unlocking them.
    </p>
    <p>
      <u>User Interface</u>
      <br>- There are new hotkeys for Speedchat (‘c’), Map (‘m’), Help Menu (‘h’), and News (‘n’) now have hotkeys associated with them.
    </p>
    <p>
      <u>Property</u>
      <br>- You will now receive a pop-message and in-game mail when your private property is approved or rejected by moderation.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Going to Forbidden Valley Small Property should no longer redirect you to the login screen. Avant Gardens Survival
      <br>- In the Survival Instance, the pre-game timer should no longer reset to 60 seconds every time a player presses “Start” while in groups of 3 or 4.
      <br>- In the Survival Instance, the “Exit” button should no longer disappear if two players leave a 3-4 player game between matches.
      <br>- Health , Imagination, and Armor are now replenished when you exit the Survival Instance.
      <br>- The leaderboard will now go away whenever you exit the Survival Instance.
      <br>- The pre-game timer will no longer start automatically, but instead when you press the “Start” button.
    </p>
    <p>
      <u>Patcher</u>
      <br>- All patcher languages are now available.
      <br>- The patcher now supports Mac.
      <br>- You can now use the Full Download option in the patcher without problems.
    </p>
    <h3>3 August 2010 (0.189.5) </h3>
    <p>
      <u>Avant Gardens</u>
      <br>- Tutorial arrows will no longer stick on-screen or go away too fast.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Your Life, Armor, and Imagination will automatically be filled up when you load into the Dragon Den Instance.
    </p>
    <p>
      <u>Race</u>
      <br>- Player names now appear correctly in leaderboards.
    </p>
    <p>
      <u>Build</u>
      <br>- You will once again receive correct brick refunds even if the server or client shuts down while building in Brick Mode.


    </p>
    <h3>2 August 2010 (0.189.4)</h3>
    <p>
      <u>Property</u>
      <br>- A tester gets booted from another tester’s property if that property is rejected by the moderators.
      <br>- Property details now display the proper description and title in all cases.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The “Roar” emote is now awarded upon completion of Numb Chuck’s “The Way of the Dragon” mission.
    </p>
    <p>
      <u>Passport</u>
      <br>- The “Ninja Warlord” achievement now has the correct text to go with it.
    </p>
  </div>
</template>
